import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { Layout } from './Layouts';
import { Comments } from './Comments';
import { Ranking } from './Ranking';
import { Finished } from './Finished';
import { Sign } from './Sign';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Router>
      <Routes>
          <Route path="/" element={<Layout/>} >
            <Route index element={<Home/>} />
            <Route path="sign" element={<Sign/>} />
            <Route path="comments" element={<Comments/>} />
            <Route path="ranking" element={<Ranking/>} />
            <Route path="finished" element={<Finished/>} />
          </Route>
      </Routes>
    </Router> 
  </React.StrictMode>
);
