import { useState } from "react";
import { useNavigate } from "react-router-dom";
import css from './Home.module.css';
import SammyDelivery from './assets/images/sammy-delivery.jpg';
import { GoThumbsdown } from "react-icons/go";
import { GoThumbsup } from "react-icons/go";


export function Sign() {
  const [confirm, setConfirm] = useState(0);
  
  const navigate = useNavigate();
  const handleConfirm = (c) => {
    setConfirm(c.target.value);
    if(parseInt(c.target.value) === 1){
        navigate('/ranking');
    }else{
      navigate('/comments');
      document.querySelector('nav').style.display = 'none'
    }
  };
  document.getElementById('signer').style.visibility = 'visible'
  document.getElementById('signer').style.height = 'auto'
  return (
    <div id="home" className="block" style={{display: 'none'}}>
        
      <h5 className="capitalize text-basic-8 md:text-28 md:leading-40 mb-4 font-semibold md:font-bold text-center mt-8 md:mt-40">
        Are you satisfied with the delivered product?
      </h5>
     
      <img src={SammyDelivery} alt='' className={css.sammy} />
      
      <div className={css.delivered}>
        <button onClick={handleConfirm} value="1" className="custom-button w-full text-primary-5 border border-primary-5 ds-button intent-primary" style={(confirm === 1 ? {background: "#c33240", color: 'white'}: {})}>
          <div className="ds-button-content">
            <span className="font-semibold"><GoThumbsup size={18} className="mb-4" style={{ color: "#c33240" }}/> Yes</span>
          </div>
        </button>
        <button onClick={handleConfirm} value="0" className="custom-button w-full text-primary-5 border border-primary-5 ds-button intent-primary" style={(confirm === 2 ? {background: "#c33240", color: 'white'}: {})}>
          <div className="ds-button-content">
            <span className="font-semibold">
              <GoThumbsdown size={18} className="mb-4" style={{ color: "#c33240" }}/> No</span>
          </div>
        </button>
      </div>
    </div>
  );
}
