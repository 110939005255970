import Thanks from "./assets/images/thanks.jpg";
import { GoArrowRight } from "react-icons/go";
export function Finished() {
 /* setInterval(() => {
    
  }, 5000);*/

  const skip = () => {
    window.location.href = "https://store.cevimed.com/";
  };
  return (
    <div className="block">
      <h5 className="capitalize text-basic-8 md:text-28 md:leading-40 mb-4 font-semibold md:font-bold text-center mt-8 md:mt-40">
        Thank you!
      </h5>
      <h6 className="font-semibold text-basic-6 text-center mb-36">
             Your comment has been sent successfully, Check our website to see more products. 
          </h6>
      <img src={Thanks} alt="Thank you!" width={320} />
      <button onClick={skip} type='button' className='border custom-button hover:border-transparent border-primary-5 mb-32 mt-32 block mx-auto w-full ds-button intent-primary' style={{width: '200px'}}> <GoArrowRight size={18} className="mb-4" style={{ color: "#c33240" }}/> Visit website</button>
    </div>
  );
}
