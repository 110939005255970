import { useNavigate } from "react-router-dom";
import { useQuery } from "./hooks/useQuery";
import { PDFViewer } from "./Viewer";
import { GoArrowRight } from "react-icons/go";
export function Home() {
  const query = useQuery();
  const invoiceID = query.get("invoiceID");
  window.invoiceID = invoiceID;
  const File = invoiceID ? (
    <PDFViewer loadFile={"./uploads/files/Invoice " + invoiceID + ".pdf"} />
  ) : (
    ""
  );
  const navigate = useNavigate();
  const handleConfirm = () => {
    navigate('/sign');
  };
  return (
    <div id="home" className="block">
      <h5 className="capitalize text-basic-8 md:text-28 md:leading-40 mb-4 font-semibold md:font-bold text-center mt-8 md:mt-40">
        Please confirm your order
      </h5>

      <div style={{ width: "80%", margin: "6px auto" }}>{File}</div>
      <button onClick={handleConfirm} type='button' className='border custom-button hover:border-transparent border-primary-5 mb-32 mt-32 block mx-auto w-full ds-button intent-primary' style={{width: '200px'}}> <GoArrowRight size={18} className="mb-4" style={{ color: "#c33240" }}/> Confirm</button>

    </div>
  );
}
