import { FaFacebookF } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function Ranking() {
  const navigate = useNavigate();
  const skip = () => {
    navigate("/finished");
    document.querySelector('nav').style.display = 'none'
  };
  return (
    <div className="block">
      <h5 className="capitalize text-basic-8 md:text-28 md:leading-40 mb-4 font-semibold md:font-bold text-center mt-8 md:mt-40">
      Review us!
      </h5>
      <h6 className="font-semibold text-basic-6 text-center mb-36">
      We want to grow as a team and company! We want to hear from our customers and show our potential customers our successful deliveries! Please review us.
      </h6>
      <h6 className="font-semibold text-basic-6 text-center mb-36">
      Ps. If you can add a picture that would be amazing! Thank you!      
      </h6>
     
      <div className="flex md:flex-row flex-col items-center md:items-start justify-start md:justify-center content-container mx-auto">
        <div className="w-full bg-basic-1 rounded relative download-card mt-40 md:mt-0 md:ml-20">
          <div className="text-center py-16 false undefined">
            <FaGoogle
              size={30}
              className="mx-auto mt-16 mb-8"
              style={{ color: "#c33240" }}
            />
            <h6 className="font-semibold text-basic-8">Google</h6>
          </div>
          <div className="mt-24 mx-24 download-content">
            <a
              href="https://g.page/r/CSZ4PvhmHpH4EB0/review"
              target="_blank"
              rel="noreferrer"
              className="dec-none-a"
            >
              <button onClick={skip} className="border custom-button hover:bg-primary-6 border-primary-5 mb-32 block mx-auto w-full bg-primary-6 ds-button text-primary-1">
                <div className="ds-button-content">
                  <span className="font-semibold"> Reviews on Google</span>
                </div>
              </button>
            </a>
          </div>
        </div>

        <div className="w-full bg-basic-1 rounded relative download-card mt-40 md:mt-0 md:ml-20">
          <div className="text-center py-16 false undefined">
            <FaFacebookF
              size={30}
              className="mx-auto mt-16 mb-8"
              style={{ color: "#005bd5" }}
            />
            <h6 className="font-semibold text-basic-8">Facebook</h6>
          </div>
          <div className="mt-24 mx-24 download-content">
            <a
              href="https://www.facebook.com/Cevimed/reviews/?ref=page_internal"
              target="_blank"
              rel="noreferrer"
              className="dec-none-a"
            >
              <button onClick={skip} className="border-primary-7 mb-32 block mx-auto w-full ds-button hover:bg-primary-7 bg-primary-7 text-primary-1">
                <div className="ds-button-content">
                  <span className="font-semibold"> Reviews on Facebook</span>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
      {/**/}

    </div>
  );
}
