import css from "./App.module.css";
import logo from "./assets/images/logo.jpg";
import "modern-normalize";
import "./global.css";
import { Link, Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
export function Layout() {
  return (
    <div className={css.app}>
      <header className={css.header}>
        <Link to={"/"}>
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </header>
      <main className={css.main}>
        <Navbar/>
        <Outlet />
      </main>
    </div>
  );
}
