import { useNavigate } from "react-router-dom";
import styles from "./Commentary.module.css";
import { GoComment } from "react-icons/go";
export function Comments() {
  const navigate = useNavigate();
  const emailjs = window.emailjs;
  const handleComment = (e) => {
    e.preventDefault();
    const data = {
      invoiceID: window.invoiceID || 0,
      customer: e.target[0].value,
      email: e.target[1].value,
      phone: e.target[2].value,
      message: e.target[3].value,
      adju: window.Signature,
    };

    emailjs.send("service_84rw24m", "template_7kfpl4o", {
      subject: `Delivered Product Reviews - Invoice ${data.invoiceID}`,
      to_name: "Simon",
      from_name: `${data.customer} - ${data.email}`,
      message: data.message,
      signature: data.adju,
    });
    navigate("/finished");
  };
  return (
    <div className="block">
      <h5 className="capitalize text-basic-8 md:text-28 md:leading-40 mb-4 font-semibold md:font-bold text-center mt-8 md:mt-40">
        How did we do?
      </h5>

      <h6 className="font-semibold text-basic-6 text-center mb-36">
        Leave us your comment...
      </h6>

      <div className="block">
        <form className={styles.commentaryForm} onSubmit={handleComment}>
          <input
            type="text"
            name="name"
            className="ds-input"
            placeholder="Name"
          />
          <input
            type="text"
            name="email"
            className="ds-input"
            placeholder="E-mail"
          />
          <input
            type="text"
            name="phone"
            className="ds-input"
            placeholder="Phone"
          />
          <textarea
            className="ds-input"
            placeholder="Write your comment"
          ></textarea>
          <button
            type="submit"
            className="border custom-button hover:border-transparent border-primary-5 mb-32 block mx-auto w-full ds-button intent-primary"
          >
            <GoComment size={18} className="mb-4" style={{ color: "#c33240" }}/> Comment
          </button>
        </form>
      </div>
    </div>
  );
}
