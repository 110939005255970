import { NavLink } from "react-router-dom"

export function Navbar() {
  return (
    <nav>
      <div
        className="sticky relative onboard-step-container overflow-hidden bg-white
          -mx-16 md:mx-auto md:w-full"
      >
        <div className="ds-step-wrapper horizontal onboard-step -ml-32 md:-ml-20 lg:mx-auto">
        <div className="stepper vertical" style={{width: "calc(25%)"}}>
            <div className="step-node custom cursor-pointer  current-step bg-basic-2">
              <NavLink to={'/'} className="step-number custom">
                <span>0</span>
              </NavLink>
              <div
                className="connect-line pointer-events-none"
                style={{width: "152.75px"}}>
              </div>
            </div>
            <div className="step-info cursor-pointer">
               <NavLink to={'/'}  className="step-title custom">
                <span className="hidden md:block font-bold">
                Confirmation
                </span>
              </NavLink>
            </div>
          </div>
          <div className="stepper vertical" style={{width: "calc(25%)"}}>
            <div className="step-node custom cursor-pointer  current-step bg-basic-2">
              <NavLink to={'/sign'} className="step-number custom">
                <span>1</span>
              </NavLink>
              <div
                className="connect-line pointer-events-none"
                style={{width: "152.75px"}}>
              </div>
            </div>
            <div className="step-info cursor-pointer">
               <NavLink to={'/sign'}  className="step-title custom">
                <span className="hidden md:block font-bold">
                Sign
                </span>
              </NavLink>
            </div>
          </div>
          <div className="stepper vertical" style={{width: "calc(25%)"}}>
            <div className="step-node custom cursor-pointer border-opacity-0 bg-basic-3">
             <NavLink to={'/ranking'} className="step-number custom">
                <span>2</span>
              </NavLink>
              <div
                className="connect-line pointer-events-none"
                style={{width: "152.75px"}}></div>
            </div>
            <div className="step-info cursor-pointer">
              <NavLink to={'/ranking'} id="ranking" className="step-title custom">
                <span className="hidden md:block font-bold">Reviews</span>
                </NavLink>
            </div>
          </div>
          <div className="stepper vertical" style={{width: "calc(25%)"}}>
            <div className="step-node custom cursor-pointer  border-opacity-0 bg-basic-3">
            <NavLink to={'/comments'} className="step-number custom">
                <span>3</span>
              </NavLink>
              <div
                className="connect-line pointer-events-none"
                style={{width: "152.75px"}}></div>
            </div>
            <div className="step-info cursor-pointer">
              <NavLink to={'/comments'} className="step-title custom">
                <span className="hidden md:block font-bold">Comment </span>
                </NavLink>
            </div>
          </div>
          <div className="stepper vertical" style={{width: "calc(25%)"}}>
            <div className="step-node custom cursor-pointer  border-opacity-0 bg-basic-3">
            <NavLink to={'/finished'} className="step-number custom">
                <span>4</span>
              </NavLink>
            </div>
            <div className="step-info cursor-pointer">
              <NavLink to={'/finished'} className="step-title custom">
                <span className="hidden md:block font-bold">
                  Thank you!
                </span>
                </NavLink>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
